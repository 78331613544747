import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }

  private url: string = environment.baseURL;
  private baseUrl: string = environment.URL;

  sendLike(data:any) {
    const liked = "liked";
    return this.http.post<any>(this.url + liked, data);
  }

  getLikes() {
    const like = "likes";
    return this.http.get(this.url + like);
  }

  getLikesCount(){
    const url="like";
    return this.http.get(this.baseUrl+url);
  }
  sendcontactRequest(object){
    const url="sendMail";
    return this.http.post(this.baseUrl+url,object);
  }
}
