import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './Components/home/home.component';
import { ContactUsComponent } from './Components/contact-us/contact-us.component';
import { FlickComponent } from './Components/flick/flick.component';
import { IosdemovideoComponent } from './Components/iosdemovideo/iosdemovideo.component';
import { EULAComponent } from './Components/eula/eula.component';
import { TermsConditionsComponent } from './Components/terms-conditions/terms-conditions.component';

const routes: Routes = [
  { path: "", component: HomeComponent, pathMatch: 'full' },
  { path: "home", component: HomeComponent },
  { path: "flick", component: FlickComponent },
  { path: "contactus", component: ContactUsComponent },
  {path: "iosdemovideo", component: IosdemovideoComponent},
  {path: "eula", component: EULAComponent},
  { path: "terms-conditions", component: TermsConditionsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
