import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/service/common.service';
import { Router } from '@angular/router';
declare var $:any
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  count: any;
  likescount: any;
 

  constructor(private user: CommonService, public router: Router) { }

  ngOnInit() {
    // this.getLikes();
    // setInterval(() => {
    //   this.getLikesCount();
    // }, 3000);
    this.loadScripts();
    // $('.installApp').modal('show');
    this.modalOpen();
    this.getLikesCount();
  }

  modalOpen(){
    var x = window.matchMedia("(max-width: 700px)");
    if (x.matches) {
      $('.installApp').modal('show');
    }
    
  }
  
  loadScripts() {
    const dynamicScripts = [
      '../../assets/js/likes.js',
      '../../assets/js/webflow.js',
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }


  sendLike(data) {
    // debugger
    this.user.sendLike(data).subscribe(
      res => {
        // debugger
        this.getLikesCount();
        // console.log(res);
      },
      err => {
        let message = 'There is an issue with service. Please retry.';
        if (err.status === 400) {
          message = 'There is an error in getting list of sold to. Please retry.';

        }
      }
    );

  }

  getLikes() {
    this.user.getLikes().subscribe(
      (data: any) => {
        this.count=data.length;
      },
      err => {
        // this.showLoadingBar = false;
        let message = 'There is an issue with service. Please retry.';
        if (err.status === 400) {
          message = 'There is an error in getting list of sold to. Please retry.';

        }
      }
    );

  }
  getLikesCount() {
    this.user.getLikesCount().subscribe(
      (data: any) => {
        // debugger
        this.likescount=data.count;
      },
      err => {
        // this.showLoadingBar = false;
        let message = 'There is an issue with service. Please retry.';
        if (err.status === 400) {
          message = 'There is an error in getting list of sold to. Please retry.';

        }
      }
    );

  }

}
