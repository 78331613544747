import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Contact } from 'src/app/models/common';
import { CommonService } from 'src/service/common.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  contactObj: Contact = new Contact();
  isBtn: boolean;
  constructor(private service:CommonService,private toaster:ToastrManager) { }

  ngOnInit() {
    this.contactForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      name: new FormControl("", [Validators.required,]),
      phone: new FormControl("", [Validators.required, ]),
      comments: new FormControl("", [Validators.required,]),
    });
  }


  sendcontactRequest(){
    // debugger
    this.isBtn=true;
   this.service.sendcontactRequest(this.contactObj).subscribe((data:any)=>{
     if(data.message == "Submitted"){
       this.isBtn=false;
       this.contactObj=new Contact();
       this.contactForm.reset();
       this.toaster.successToastr("Thank you for getting in touch!");
     }

// debugger
   })
  }

}
